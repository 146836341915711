import { BackgroundImage } from '@pancakeswap/uikit'
import styled from 'styled-components'

const WrapperBecauseSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .header {
    max-width: 755px;
    text-align: center;
    h1 {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      font-weight: 500;
      text-transform: uppercase;
    }

    .listWhy {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 15px;
    }
  }

  .imgBanner {
    width: 241px;
    height: 211px;
    div {
      margin: 0 auto;
    }
  }
`

const BecauseSection = () => {
  return (
    <WrapperBecauseSection>
      <div className="imgBanner">
        <BackgroundImage src="/images/landing-page/product-7.png" width={241} height={211} />
      </div>
      <div className="header">
        <h1>
          Because of that, MOVE is a fertile ground for ongoing R&D, broadening horizons for DEX possibilities. And with
          all these, we’re step closer to bridging the gap between CEX and DEX experiences.
        </h1>
      </div>
    </WrapperBecauseSection>
  )
}

export default BecauseSection
