import { BackgroundImage } from '@pancakeswap/uikit'
import styled from 'styled-components'
import CardItemWhy, { CardItemWhyType } from './CardItemWhy'

const WrapperWhySection = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 40px 20px;
  width: 100%;

  .header {
    display: flex;
    flex-direction: column;
    gap: 30px;

    h1 {
      font-size: 40px;
      line-height: 48px;
      font-weight: 600;
      color: #ffd012;
      width: 100%;
    }

    .sub-title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      color: #fff;
      width: 100%;
    }

    .description {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: #ffffffa0;
      width: 100%;
    }

    .listWhy {
      display: grid;
      grid-template-columns: 1fr;
      gap: 15px;
      z-index: 2;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 30px;
    .header {
      .listWhy {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 30px;
    .header {
      h1 {
        width: 60%;
      }
      .sub-title {
        width: 60%;
      }
      .description {
        width: 60%;
      }

      .listWhy {
        grid-template-columns: 1fr 1fr;
      }
    }

    .imgBanner {
      width: 530px;
      height: 413px;
      position: absolute;
      bottom: 300px;
      right: -100px;
      z-index: 0;

      .blurColor {
        position: absolute;
        z-index: -1;
        top: 150px;
        right: 160px;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background-color: var(--colors-primary);
        filter: blur(100px);
      }

      img {
        width: 530px;
        height: 413px;
        position: relative;
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    gap: 30px;
    .header {
      .listWhy {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        width: 80%;
      }
    }

    .imgBanner {
      width: 530px;
      height: 413px;
      position: absolute;
      bottom: 50px;
      right: -100px;
      z-index: 0;

      .blurColor {
        position: absolute;
        z-index: -1;
        top: 200px;
        right: 160px;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background-color: var(--colors-primary);
        filter: blur(100px);
      }

      img {
        width: 530px;
        height: 413px;
        position: relative;
      }
    }
  }
`

const fakeDataDexSection: CardItemWhyType[] = [
  {
    id: 1,
    image: '/images/landing-page/why-1.png',
    title: 'No congestion',
  },
  {
    id: 2,
    image: '/images/landing-page/why-2.png',
    title: 'Lower latency, crucial for trading',
  },
  {
    id: 3,
    image: '/images/landing-page/why-3.png',
    title: 'High throughput',
  },
  {
    id: 4,
    image: '/images/landing-page/why-4.png',
    title: 'Low fees',
  },
  {
    id: 5,
    image: '/images/landing-page/why-5.png',
    title: 'Instant block finality',
  },
]

const WhySection = () => {
  return (
    <WrapperWhySection>
      <div className="header">
        <h1>Why Move ?</h1>
        <span className="sub-title">
          MOVE unifies the blockchain world, breaking down barriers between platforms while prioritizing accessibility
          and future-proof innovation. It empowers everyone, from novices to experts, to connect, collaborate, and
          create within a vibrant, community-driven ecosystem.
        </span>
        {/* <span className="description">
          Twin-turbo consensus and market-based parallelization solve key challenges faced by many general-purpose L1s:
        </span> */}
        <div className="listWhy">
          {fakeDataDexSection.map((item) => (
            <CardItemWhy key={`${item.id}`} data={item} />
          ))}
        </div>
      </div>
      <div className="imgBanner">
        <div className="blurColor" />
        <BackgroundImage src="/images/landing-page/multi-egg.png" width={530} height={413} />
      </div>
    </WrapperWhySection>
  )
}

export default WhySection
