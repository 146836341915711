import { BackgroundImage } from '@pancakeswap/uikit'
import styled from 'styled-components'

const WrapperIntroDexSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 40px;
  padding: 50px 20px;
  gap: 40px;

  .imgBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
    padding: 0 20px;

    h1 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      color: rgba(255, 208, 18, 1);
    }

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
    justify-content: end;
    position: relative;
    width: 100%;
    border-radius: 40px;
    padding: 200px 30px;

    .imgBanner {
      position: absolute;
      top: 40px;
      left: -100px;
      width: 554px;
      height: 451px;
      z-index: 3;
    }

    .title {
      position: relative;
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      text-align: start;
      padding: 0;
    }
  }
`

const IntroDexSection = () => {
  return (
    <WrapperIntroDexSection>
      <div className="imgBanner">
        <BackgroundImage src="/images/landing-page/movement.png" width={554} height={451} />
      </div>
      <div className="title">
        {/* <div className="blurColor" />
        <div className="blurColor1" /> */}
        <h1>
          Dexlyn is a decentralized AMM native to the Movement leveraging everything a paralleized EVM has to offer.
        </h1>
        <span>
          Designed to be user-friendly, Movements is a community-driven, high-performance parallelized liquidity hub,
          seamlessly integrated with Movement.
        </span>
      </div>
    </WrapperIntroDexSection>
  )
}

export default IntroDexSection
