import Image from 'next/image'
import styled from 'styled-components'

const WrapperOurPartnerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    padding: 30px 0;
    width: 100%;
    border-top: 1px solid #302600;
    border-bottom: 1px solid #302600;
    .wrapper-text {
      position: relative;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 20px;

      .line-partner {
        display: none;
      }

      h1 {
        font-size: 40px;
        line-height: 48px;
        font-weight: 600;
        width: 100%;
        color: #fff;
        text-transform: uppercase;
      }

      .main-text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        width: 100%;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #302600;
    .main-list {
      max-width: 1200px;
      margin: 0 auto;
      display: grid;
      align-items: center;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      width: 100%;
      padding: 0 20px;

      .imgBanner:nth-child(1),
      .imgBanner:nth-child(3),
      .imgBanner:nth-child(5),
      .imgBanner:nth-child(7) {
        border-top: none;
        border-bottom: none;
      }

      .imgBanner:nth-child(8) {
        border-bottom: none;
      }

      .imgBanner {
        display: grid;
        width: 100%;
        height: 220px !important;
        align-items: center;
        justify-content: center;
        border: 1px solid #302600;

        img {
          width: 120px !important;
          height: auto;
          position: unset !important;
        }
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    .main {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid #302600;
      .main-list {
        max-width: 1200px;
        margin: 0 auto;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: 100%;
        padding: 0 20px;

        .imgBanner:nth-child(1),
        .imgBanner:nth-child(3),
        .imgBanner:nth-child(5) {
          border-top: none;
          border-right: none;
          border-bottom: 1px solid #302600;
        }

        .imgBanner:nth-child(2),
        .imgBanner:nth-child(4),
        .imgBanner:nth-child(6) {
          border-top: none;
          border-bottom: 1px solid #302600;
        }

        .imgBanner:nth-child(7) {
          border-bottom: none;
        }

        .imgBanner:nth-child(8) {
          border-bottom: none;
          border-left: none;
          border-top: none;
        }

        .imgBanner {
          display: grid;
          width: 100%;
          height: 220px !important;
          align-items: center;
          justify-content: center;
          border: 1px solid #302600;

          img {
            width: 120px !important;
            height: auto;
            position: unset !important;
          }
        }
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    .header {
      padding: 30px 0;
      width: 100%;
      border-top: 1px solid #302600;
      border-bottom: 1px solid #302600;
      .wrapper-text {
        position: relative;
        width: 100%;
        gap: 20px;
        padding: 0 0px 0px 20px;

        .line-partner {
          position: absolute;
          top: 70px;
          right: 200px;
          width: 260px;
          rotate: 130deg;
          height: 1px;
          background-color: #302600;
        }

        h1 {
          width: 60%;
        }

        .main-text {
          width: 60%;
        }
      }
    }

    .main {
      .main-list {
        grid-template-columns: repeat(4, minmax(0, 1fr));

        .imgBanner:nth-child(2),
        .imgBanner:nth-child(4) {
          border-top: none;
          border-bottom: none;
          border-right: 1px solid #302600;
          border-left: 1px solid #302600;
        }

        .imgBanner:nth-child(6) {
          border-top: 1px solid #302600;
        }

        .imgBanner:nth-child(3),
        .imgBanner:nth-child(7) {
          border: none;
        }

        .imgBanner:nth-child(1),
        .imgBanner:nth-child(5) {
          border-left: 1px solid #302600;
          border-top: none;
          border-bottom: none;
          border-right: none;
        }

        .imgBanner:nth-child(7),
        .imgBanner:nth-child(5) {
          border-top: 1px solid #302600;
        }

        .imgBanner:nth-child(6),
        .imgBanner:nth-child(8) {
          border-bottom: none;
        }

        .imgBanner:nth-child(8) {
          border-top: 1px solid #302600;
          border-left: 1px solid #302600;
        }
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    .header {
      .wrapper-text {
        width: 100%;
        padding: 0 0px 0px 20px;
        .line-partner {
          display: block;
        }

        h1 {
          width: 60%;
        }

        .main-text {
          width: 60%;
        }
      }
    }
  }
`

const OurPartnerSection = () => {
  return (
    <WrapperOurPartnerSection>
      <div className="header">
        <div className="wrapper-text">
          <h1>Our Partners</h1>
          <div className="main-text">
            At DexlynSwap, we are proud to collaborate with some of the most innovative and impactful projects in the
            MOVE Ecosystem. Our partners play a crucial role in our success, and together, we are strengthening and
            enhancing the MOVE Ecosystem.
          </div>
          <div className="line-partner" />
        </div>
      </div>
      <div className="main">
        <div className="main-list">
          <div className="imgBanner">
            <Image src="/images/landing-page/logo-movement.svg" fill alt="" />
          </div>
          <div className="imgBanner">
            <Image src="/images/landing-page/logo.svg" fill alt="" />
          </div>
          <div className="imgBanner">
            <Image src="/images/landing-page/logo-movement.svg" fill alt="" />
          </div>
          <div className="imgBanner">
            <Image src="/images/landing-page/logo.svg" fill alt="" />
          </div>
          <div className="imgBanner">
            <Image src="/images/landing-page/logo-movement.svg" fill alt="" />
          </div>
          <div className="imgBanner">
            <Image src="/images/landing-page/logo.svg" fill alt="" />
          </div>
          <div className="imgBanner">
            <Image src="/images/landing-page/logo-movement.svg" fill alt="" />
          </div>
          <div className="imgBanner">
            <Image src="/images/landing-page/logo.svg" fill alt="" />
          </div>
        </div>
      </div>
    </WrapperOurPartnerSection>
  )
}

export default OurPartnerSection
