/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { AddIcon, MinusIcon } from '@pancakeswap/uikit'
import { useState } from 'react'
import styled from 'styled-components'

const WrapperCardItemFAQ = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .headerFAQ {
    cursor: pointer;
    padding: 20px;
    border-radius: 16px;
    background-color: #0a0800;
    border: 1px solid #111111;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: normal;
      font-weight: 500;
      color: #fff;
    }

    .icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .descriptionFAQ {
    padding: 0px;
    border-radius: 16px;
    background-color: #111111;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: normal;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0s ease-in-out;
    &.open {
      padding: 20px;
      max-height: 500px; /* Adjust as needed to fit content */
    }
  }
`

export interface CardItemFAQType {
  id: number
  title: string
  value: string
}

interface CardItemFAQProps {
  data: CardItemFAQType
}

const CardItemFAQ = ({ data }: CardItemFAQProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleOpenFAQ = () => {
    setIsOpen(!isOpen)
  }

  return (
    <WrapperCardItemFAQ>
      <div className="headerFAQ" onClick={handleOpenFAQ}>
        <span>{data.title}</span>
        <div className="icon">
          {isOpen ? <MinusIcon width={24} height={24} color="#fff" /> : <AddIcon width={24} height={24} color="#fff" />}
        </div>
      </div>
      <div className={`descriptionFAQ ${isOpen ? 'open' : ''}`}>
        <span>{data.value}</span>
      </div>
    </WrapperCardItemFAQ>
  )
}

export default CardItemFAQ
