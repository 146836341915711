import { BackgroundImage } from '@pancakeswap/uikit'
import styled from 'styled-components'

const WrapperCardItemFeature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
  background: linear-gradient(180deg, rgba(5, 5, 5, 0.8) 0%, rgba(10, 8, 0, 0.8) 100%);
  border-radius: 20px;
  border: 1px solid rgba(48, 48, 48, 1);
  .image {
    width: 36px;
    height: 36px;
  }

  .titleFeature {
    font-size: 16px;
    line-height: 24px;
    padding: 0 30px;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    .titleFeature {
      font-size: 24px;
      line-height: 32px;
      padding: 0 30px;
      font-weight: 500;
      text-align: center;
      color: #fff;
    }
  }
`

export interface CardItemFeatureType {
  id: number
  image: string
  title: string
}

interface CardItemFeatureProps {
  data: CardItemFeatureType
}

const CardItemFeature = ({ data }: CardItemFeatureProps) => {
  return (
    <WrapperCardItemFeature>
      <div className="image">
        <BackgroundImage src={data.image} width={36} height={36} />
      </div>
      <span className="titleFeature">{data.title}</span>
    </WrapperCardItemFeature>
  )
}

export default CardItemFeature
