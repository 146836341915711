import styled from 'styled-components'
import CardItemFAQ from './CardItemFAQ'

const WrapperFAQSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 0 20px;

  .header {
    text-align: center;
    h1 {
      font-size: 40px;
      line-height: 48px;
      font-weight: 600;
    }
  }

  .listFAQ {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: 20px;
  }
`

const dataFake = [
  {
    id: 1,
    title: 'What is DexlynSwap protocol?',
    value:
      'The DexlynSwap Protocol is a publicly accessible, open-source framework designed for facilitating liquidity and enabling the trade of ERC20 tokens on the Movement EVM network. It bypasses the need for trusted middlemen and removes superfluous rent-seeking behavior, promoting secure, user-friendly, and efficient trading activities. This protocol is constructed to be permanent and non-upgradeable, ensuring it remains impervious to censorship.',
  },
  {
    id: 2,
    title: 'What’s going to be DexlynSwap v1?',
    value:
      'DexlynSwap will be having a set of initial AMM features such as swaps, liquidity pools, and a lot of info & analytics features. Alongside that, DexlynSwap wants to help the ecosystem grow as much as possible, so there’ll be some open source APIs to help developers get some information such as price feeds, etc.',
  },
  // {
  //   id: 3,
  //   title: 'Is DexlynSwap team anon?',
  //   value:
  //     'No, DexlynSwap is being built by NIMA Enterprises, a team which has previously built and run some notable protocols such as Avalaunch, Peek NFT, etc.',
  // },
  {
    id: 3,
    title: 'Is DexlynSwap going to be only on the Movement?',
    value:
      'Yes, DexlynSwap will be deployed to the MOVE EVM only. It’s not meant or supposed to be a multichain product.',
  },
]

const FAQSection = () => {
  return (
    <WrapperFAQSection>
      <div className="header">
        <h1>FAQ</h1>
      </div>
      <div className="listFAQ">
        {dataFake.map((item) => (
          <CardItemFAQ key={item.id} data={item} />
        ))}
      </div>
    </WrapperFAQSection>
  )
}

export default FAQSection
