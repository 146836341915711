import { Box, PageSection, useMatchBreakpoints } from '@pancakeswap/uikit'
import BecauseSection from './components/BecauseSection'
import FAQSection from './components/FAQSection'
import FeatureSection from './components/FeatureSection'
import IntroDexSection from './components/IntroDexSection'
import OurPartnerSection from './components/OurPartnerSection'
import TopSection from './components/TopSection'
import WhySection from './components/WhySection'

const LandingPage = () => {
  //   const { theme } = useTheme()
  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '1200px', padding: '0px 0px' }
  const HomeFullSectionContainerStyles = { margin: '0', width: '100%', padding: '0px 0px' }
  const HomeFullWithPaddingSectionContainerStyles = {
    margin: '0',
    width: '100%',
    padding: '48px 0px',
  }
  const HomeMaxWidthPaddingSectionContainerStyles = {
    margin: '0',
    width: '100%',
    maxWidth: 'unset',
    padding: '48px 0px',
  }
  //   const { t } = useTranslation()
  const { isMobile, isTablet } = useMatchBreakpoints()
  return (
    <Box
      style={{
        width: isMobile ? '100vw' : 'calc(100vw - 8px)',
        overflow: 'hidden',
        boxSizing: 'border-box',
        marginTop: '50px',
        padding: isTablet || isMobile ? '0px 0px' : '',
        background: 'url("/images/background-all.png")',
        zIndex: 100,
      }}
    >
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        backgroundImage="/images/background.png"
        index={2}
        hasCurvedDivider={false}
      >
        <TopSection />
      </PageSection>
      {/* <PageSection
        innerProps={{ style: HomeFullSectionContainerStyles }}
        background={!isTablet && !isMobile ? 'url("/images/landing-page/background-1.png")' : ''}
        // backgroundSize="cover"
        // backgroundRepeat="repeat-x"
        index={2}
        hasCurvedDivider={false}
      >
        <ListDexSection />
      </PageSection> */}
      <PageSection
        innerProps={{ style: HomeFullWithPaddingSectionContainerStyles }}
        // background={
        //   !isTablet && !isMobile
        //     ? 'linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 1)), url("/images/landing-page/background-2.png")'
        //     : ''
        // }
        // backgroundSize="cover"
        index={2}
        hasCurvedDivider={false}
      >
        <IntroDexSection />
      </PageSection>
      <PageSection innerProps={{ style: HomeFullWithPaddingSectionContainerStyles }} index={2} hasCurvedDivider={false}>
        <FeatureSection />
      </PageSection>
      <PageSection innerProps={{ style: HomeFullWithPaddingSectionContainerStyles }} index={2} hasCurvedDivider={false}>
        <WhySection />
      </PageSection>
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        index={2}
        hasCurvedDivider={false}
        padding={isTablet || isMobile ? '40px 20px' : '40px 0 100px 0'}
        style={{
          width: '100svw',
          maxWidth: '100svw',
        }}
      >
        <BecauseSection />
      </PageSection>
      <PageSection innerProps={{ style: HomeMaxWidthPaddingSectionContainerStyles }} index={2} hasCurvedDivider={false}>
        <OurPartnerSection />
      </PageSection>
      <PageSection innerProps={{ style: HomeFullWithPaddingSectionContainerStyles }} index={2} hasCurvedDivider={false}>
        <FAQSection />
      </PageSection>
    </Box>
  )
}

export default LandingPage
