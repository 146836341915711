import Image from 'next/image'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 48px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    h1 {
      font-size: 64px;
      font-weight: 600;
      line-height: 72px;
      text-transform: uppercase;
      color: #fff;

      span {
        font-size: 64px;
        font-weight: 600;
        line-height: 72px;
        text-transform: uppercase;
        color: #ffd012;
      }
    }

    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
    }
  }
  .imgBanner {
    width: 471px;
    height: 561px;
    zoom: 110%;
    position: relative;
    .blurColor {
      position: absolute;
      z-index: -1;
      top: 0px;
      right: 0px;
      width: 400px;
      height: 400px;
      border-radius: 50%;
      background-color: var(--colors-primary);
      filter: blur(100px);
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0px 50px;

    flex-direction: row;
    .title {
      width: 40%;
    }
  }
`

const TopSection = () => {
  return (
    <Wrapper>
      <div className="title">
        <h1>
          The Native DEX <span>on MOVE</span>
        </h1>
        <span>Where Legends Trade</span>
      </div>
      <div className="imgBanner">
        <Image src="/images/landing-page/main-pet.png" fill alt="" />
      </div>
    </Wrapper>
  )
}

export default TopSection
