import { BackgroundImage } from '@pancakeswap/uikit'
import styled from 'styled-components'
import CardItemFeature, { CardItemFeatureType } from './CardItemFeature'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 0 20px;
  align-items: center;
  text-align: center;
  .title {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .imgBanner {
      width: 100px;
      height: 120px;
    }

    .title-feature {
      h1 {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .listFeature {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    .listFeature {
      grid-template-columns: 1fr 1fr;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0;

    .title {
      gap: 30px;
      .title-feature {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: 40px;

        .line {
          width: 250px;
          height: 2px;
          background: linear-gradient(90deg, #000000 0%, #ffcc00 100%);
        }

        .line-re {
          rotate: 180deg;
          width: 250px;
          height: 2px;
          background: linear-gradient(90deg, #000000 0%, #ffcc00 100%);
        }

        h1 {
          font-size: 40px;
          line-height: 48px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
    .listFeature {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`

const fakeDataDexSection: CardItemFeatureType[] = [
  {
    id: 1,
    image: './images/landing-page/product-1.png',
    title: 'Blazing Fast Speed with Near Instant Finality',
  },
  {
    id: 2,
    image: './images/landing-page/product-2.png',
    title: 'Swap Any Token on Movement',
  },
  {
    id: 3,
    image: './images/landing-page/product-3.png',
    title: 'Industry Leading Analytics',
  },
  {
    id: 4,
    image: './images/landing-page/product-4.png',
    title: 'Share in Protocol Incentives and Fees',
  },
  {
    id: 5,
    image: './images/landing-page/product-5.png',
    title: 'Support for DeFi Innovators',
  },
  {
    id: 6,
    image: './images/landing-page/product-6.png',
    title: 'Permissionless Pools',
  },
]

const FeatureSection = () => {
  return (
    <Wrapper>
      <div className="title">
        <div className="imgBanner">
          <BackgroundImage src="/images/landing-page/features.png" width={100} height={120} />
        </div>
        <div className="title-feature">
          <div className="line" />
          <h1>Our Product Features</h1>
          <div className="line-re" />
        </div>
      </div>
      <div className="listFeature">
        {fakeDataDexSection.map((item) => (
          <CardItemFeature key={`${item.id}`} data={item} />
        ))}
      </div>
    </Wrapper>
  )
}

export default FeatureSection
