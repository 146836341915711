import { BackgroundImage } from '@pancakeswap/uikit'
import styled from 'styled-components'

const WrapperCardItemWhy = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  height: auto;
  background-color: #111111;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  gap: 10px;
  .image {
    width: 49px;
    height: 48px;
  }

  .title {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }
`

export interface CardItemWhyType {
  id: number
  image: string
  title: string
}

interface CardItemWhyProps {
  data: CardItemWhyType
}

const CardItemWhy = ({ data }: CardItemWhyProps) => {
  return (
    <WrapperCardItemWhy>
      <div className="image">
        <BackgroundImage src={data.image} width={49} height={48} />
      </div>
      <span className="title">{data.title}</span>
    </WrapperCardItemWhy>
  )
}

export default CardItemWhy
